const browserSupportVersions = {
    "chrome":49.0,
    "firefox":52.0,
    "ie":8.1,
    "edge":0.0,
    "safari":9.0,
    "opera":0.0
}

const uuidv4 = ()=>{
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const ArrayToMap = (arrayObject,Key)=>{
    let keyMap = new Map();
    for(let item of arrayObject)
        keyMap.set(item[Key],item)
    return keyMap;
}
const GetCategoryPath = (catCode,tail,Path)=>{
    if(catCode==tail.code) {
        let temp = Object.assign({},tail)
        delete temp.children;
        Path.push(temp);
        return true;
    }
    else{
        if(tail.children) {
            for (let children of tail.children) {
                let isPath = GetCategoryPath(catCode, children, Path)
                if (isPath) {
                    let temp = Object.assign({},children)
                    delete temp.children;
                    Path.push(temp);
                    return true;
                }
            }
        }
    }
    return false;
}

const mySleep  = (ms)=>{
    return new Promise(resolve => setTimeout(resolve, ms));
}
const App =  {template: `<appComponent></appComponent>`};
const Home = {template: `<homeComponent></homeComponent>`};
const AppFilter = {template: `<genericFilterComponent></genericFilterComponent>`};
const LoginPage = {template: `<loginPage></loginPage>`};
const BrowserSupportView = {template:`<BrowserSupportComponent></BrowserSupportComponent>`};
const P404 = {template: `<p404Component></p404Component>`};
const Badges = {template: `<badgesComponent></badgesComponent>`};
const ItemView = {template: `<itemViewComponent></itemViewComponent>`};
const OrderDetail ={template: `<orderDetailComponent></orderDetailComponent>`};
const OrderView = {template: `<orderViewComponent></orderViewComponent>`};
const FavoritiesView = {template:`<favoritiesComponent></favoritiesComponent>`};
const EcofriendlyView = {template:`<ecofriendlyComponent></ecofriendlyComponent>`};
const GiftView = {template:`<customerGiftsComponent></customerGiftsComponent>`};
const SuggestionView = {template:`<suggestionComponent></suggestionComponent>`};
const GenericPersonView = { template:`<profileComponent></profileComponent>`};
const ReportPage = { template:`<genericReportPage></genericReportPage>`};
const IndicatorPage = { template:`<genericIndicatorPage></genericIndicatorPage>`};
const StaticView = { template:`<staticViewPage></staticViewPage>`};
function classificationRouterHelpper(categoryName,from,to){
    categoryName = categoryName.replace("%2F","/")
    if (storeApp.state.items_store.size==0) {
        console.log('Wait Classificaction');
        clearTimeout();
        setTimeout(classificationRouterHelpper ,1500, categoryName,from,to);
    }
    else {
        let catData  =  storeApp.state.cat_by_name.get(categoryName);
        //console.log('Run Classificaction',catData);
        if(!catData)
            return ;
        clearTimeout();
        //console.log("TEST",catData.cod,store.state.filters.length)
        if(store.state.filters.length==0) {
            let objectFilter = {};
            objectFilter.name = uuidv4();
            objectFilter.type='globalClassification';
            objectFilter.condition = `x.Classification.split(',').indexOf('${catData.code}')!=-1`;
            objectFilter.label =catData.code;
            objectFilter.code = catData.code;
            objectFilter.filterField = "Classification";
            objectFilter.result = true;
            storeApp.dispatch('addGlobalFilter',objectFilter);
            storeApp.dispatch('updateCategory',catData);
        }
    }

}


const globalRouter= [
        { name: 'HomeRoot',path: '/', component: Home},
        { name: 'HomeLink',path: '/home', component: Home },
        { name: 'GenericFilters', path: '/filter/:aliasCode', component: AppFilter},
        { name: 'LoginPage',path: '/login', component: LoginPage },
        { name: 'Gifts'     ,path: '/gifts', component: GiftView},
        { name: 'Suggestions' ,path: '/suggestions', component: SuggestionView},
        { name: 'EcofriendlyList',path: '/ecofriendly', component: EcofriendlyView },
        { name: 'ItemList',path: '/items', component: App },
        { name: 'ItemView',path: '/view/:id', component: ItemView },
        { name: 'Favorities',path: '/favorities', component: FavoritiesView},
        { name: 'Badges',path: '/badges', component: Badges },
        { name: 'CatSearch',path: '/search/:categoryString', component: App },
        { name: 'Shopping',path: '/latestshopping/:filter', component: OrderView },
        { name: 'OrderDetail',path: '/orderdetail/:orderid', component: OrderDetail },
        { name: 'Invoices',path: '/invoices', component: InvoiceList },
        { name: 'CatByName',path: '/category/:categoryName', component: App ,
            beforeEnter:(to,from,next)=>{
                console.log('before Enter');
                classificationRouterHelpper(to.params.categoryName,from,to);
                next();
            }
        },
        { name: 'PersonInfo',path: '/genericuser', component: GenericPersonView },
        { name: 'ReportView',path: '/report/:reportName', component: ReportPage },
        { name: 'IndicatorView',path: '/indicators', component: IndicatorPage },
        { name: 'StaticView',path: '/company/:staticName', component: StaticView },
        { name: 'BrowserSupport',path: '/browsers', component: BrowserSupportView }, // all paths are defined without the hash.
        { name: 'P404',path: '/*', component: P404 , alias:"*"}, // all paths are defined without the hash.
];

